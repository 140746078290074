import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionLink } from '@creditas/button'
import { Typography } from '@creditas/typography'
import { ToasterMessage, ToasterAction } from '@creditas/toaster'

interface Props {
  data: {
    redirectTo: string
  }
}

const PendingAcquittanceLoanBillet = ({ data: { redirectTo } }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <ToasterMessage data-testid="pending-loan-billet">
        <Typography variant="support">
          {t('notify.PendingAcquittance.description')}
        </Typography>
      </ToasterMessage>
      <ToasterAction>
        <ActionLink
          onClick={() => window.singleSpaNavigate(redirectTo)}
          style={{
            padding: 0,
            textAlign: 'center',
          }}
        >
          {t('notify.PendingAcquittance.action')}
        </ActionLink>
      </ToasterAction>
    </>
  )
}

export { PendingAcquittanceLoanBillet }
