import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas/typography'
import { ToasterMessage } from '@creditas/toaster'

interface Props {
  data?: any
}

const SalaryAdvanceSolicitationReceived = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <ToasterMessage data-testid="salary-advance-solicitation">
      <Typography variant="form">
        {t('notify.SalaryAdvanceSolicitation.title')}
      </Typography>
      <Typography variant="support">
        {t('notify.SalaryAdvanceSolicitation.description')}
        <br />
      </Typography>
    </ToasterMessage>
  )
}

export { SalaryAdvanceSolicitationReceived }
