import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas/typography'
import { ToasterMessage } from '@creditas/toaster'

interface Props {
  data?: any
}

const LoanSolicitationReceived = ({ data }: Props) => {
  const { t } = useTranslation()

  return (
    <ToasterMessage data-testid="loan-solicitation">
      <Typography variant="form">
        {t('notify.LoanSolicitation.title')}
      </Typography>
      <Typography variant="support">
        {t('notify.LoanSolicitation.description')}
      </Typography>
    </ToasterMessage>
  )
}

export { LoanSolicitationReceived }
