import React, { useEffect, useState } from 'react'
import { useToaster } from '@creditas/toaster'
import { getNotificationComponent } from './helpers'

const Notifications = () => {
  const { push } = useToaster()
  const [componentId, setComponentId] = useState('')
  const [componentData, setComponentData] = useState({})

  const onClose = () => {
    setComponentId('')
    setComponentData({})
  }

  useEffect(() => {
    document.addEventListener('notifications', handleComponentId)
    return () => {
      document.removeEventListener('notifications', handleComponentId)
    }
  }, [])

  useEffect(() => {
    if (!componentId) return
    const { Component, options } = getNotificationComponent(componentId)
    if (!Component) return
    push(<Component data={componentData} />, { ...options, onClose })
  }, [componentId])

  function handleComponentId(event: any) {
    const { id, data } = event?.detail
    setComponentId(id)
    setComponentData(data)
  }

  return <></>
}

export { Notifications }
