import React from 'react'
import { TextAndSubtextContainer, Text, Subtext } from './TextAndSubtext.style'

interface TextAndSubtextProps {
  text: string
  subtext: string
  orientation?: 'vertical' | 'horizontal'
}

export function TextAndSubtext({
  text,
  subtext,
  orientation = 'horizontal',
}: TextAndSubtextProps) {
  return (
    <TextAndSubtextContainer
      data-testid="text-and-subtext-component"
      orientation={orientation}
    >
      <Text>{text}</Text>
      <Subtext>{subtext}</Subtext>
    </TextAndSubtextContainer>
  )
}
