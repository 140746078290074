import { styled } from '@creditas/stylitas'

interface TextAndSubtextProps {
  orientation: 'vertical' | 'horizontal'
}

export const TextAndSubtextContainer = styled.div<TextAndSubtextProps>`
  display: flex;
  justify-content: space-between;

  flex-direction: ${props =>
    props.orientation === 'horizontal' ? 'row' : 'column'};
`

export const Text = styled.p`
  font-weight: 600;
  line-height: 24px;
`

export const Subtext = styled.p`
  line-height: 24px;
`
