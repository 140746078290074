import {
  LoanSolicitationReceived,
  PendingAcquittanceLoanBillet,
  SalaryAdvanceSolicitationReceived,
} from '../components'

const getNotificationComponent = (componentId: string) => {
  const data = {
    loanSolicitationReceived: {
      Component: LoanSolicitationReceived,
      options: {
        level: 'success',
        delay: -1,
      },
    },
    salaryAdvanceSolicitationReceived: {
      Component: SalaryAdvanceSolicitationReceived,
      options: {
        level: 'success',
        delay: -1,
      },
    },
    pendingAcquittanceLoanBillet: {
      Component: PendingAcquittanceLoanBillet,
      options: {
        level: 'info',
        delay: -1,
      },
    },
  }

  return data[componentId] || {}
}

export { getNotificationComponent }
