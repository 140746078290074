import React from 'react'

import { Notifications } from './Notifications'
import { TranslatedErrorBoundary } from '../../components'

import tokens from '@creditas/tokens'
import { ThemeProvider } from '@creditas/stylitas'
import { ToasterProvider } from '@creditas/toaster'

class App extends React.Component {
  componentDidCatch() {}

  render() {
    return (
      <TranslatedErrorBoundary>
        <ThemeProvider theme={tokens}>
          <ToasterProvider>
            <Notifications />
          </ToasterProvider>
        </ThemeProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
