import { css, styled } from '@creditas/stylitas'

interface LineProps {
  marginTop: number
  marginBottom: number
  marginRight: number
  marginLeft: number
  orientation: 'vertical' | 'horizontal'
  color: string
}

export const LineContainer = styled.div<LineProps>`
  ${props =>
    props.orientation === 'horizontal' &&
    css`
      border-top: 1px solid ${props.color};
      margin-top: ${props.marginTop}px;
      margin-bottom: ${props.marginBottom}px;
    `}

  ${props =>
    props.orientation === 'vertical' &&
    css`
      border-left: 1px solid ${props.color};
      margin-left: ${props.marginLeft}px;
      margin-right: ${props.marginRight}px;
    `}
`
