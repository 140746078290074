import { datadogLogs, Logger } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { envVars } from '../../envVars'
import { User } from '@datadog/browser-core'

const allowedEnvs = ['production', 'staging']

const env = envVars.environment as string
const applicationId = envVars.DATADOG_APPLICATION_ID as string
const clientToken = envVars.DATADOG_CLIENT_TOKEN as string
const service = 'payroll-loan-status-frontend'
const allowed = allowedEnvs.includes(env)

function startLogs() {
  if (!allowed) {
    return
  }

  datadogLogs.init({
    clientToken,
    service,
    env,
    site: 'datadoghq.com',
    sessionSampleRate: 100,
  })
}

function startMonitoring() {
  if (!allowed) {
    return
  }

  datadogRum.init({
    applicationId,
    clientToken,
    service,
    env,
    site: 'datadoghq.com',
    sessionSampleRate: 10,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

function setUserContext(user: User) {
  if (datadogRum.getInternalContext()) {
    datadogRum.setUser(user)
  }
}

function logger() {
  return datadogLogs.logger
}

export { logger, startLogs, startMonitoring, setUserContext }
